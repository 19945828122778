import { createGlobalStyle } from "styled-components"
import styledNormalize from "styled-normalize"

export const colors = {
  main: "#ff3d38",
  secondary: "#2b45c0",
  background: "#080a32",
  text: "#ffffff",
  curators: "#ff3d38",
  white: "#ffffff",
  blue: "#121469",
  beige: "#D4C47F",
}
export const mq = {
  medium: "@media screen and (min-width: 640px)",
  large: "@media screen and (min-width: 1200px)",
  xlarge: "@media screen and (min-width: 2000px)",
}

export const GlobalStyle = createGlobalStyle`
    ${styledNormalize}
    html {
        box-sizing: border-box;
        font-family: 'GT Walsheim Pro', Arial, sans-serif;
        height: 100dvh;
    }

    *, *:before, *:after {
        margin-top: 0;
        box-sizing: inherit;
        color: ${colors.text};
    }

    body {
        background-color: ${colors.background};
    }

    p {
        font-size: 15px;
    }

    h1, h2, h3, h4, h5, h6 {
        color: ${colors.main};
        margin-bottom: 0.2em;
    }

    h1 {
        font-size: 20px;
    }

    .carousel .slide {
        display: flex;
        justify-content: center;
        background-color: ${colors.background};
    }

    /* 
    * Images from WP
    */
    img {
        max-width: 100%;
        height: auto;
    }

    img.aligncenter {
        display: block;
        margin: auto;
    }

    /*
    * Galleries
    */
    .gallery {
        display: flex;
        align-items: center;
        margin: 0 -1rem;
        flex-wrap: wrap;
    }

    .gallery .gallery-item {
        padding: 1rem;
        margin: 0;
    }

    .gallery .gallery-item img {
        max-width: 100%;
        height: auto;
    }

    .gallery.gallery-columns-4 .gallery-item {
        flex-basis: 50%;

        ${mq.medium} {
            flex-basis: 25%;
        }
    }

    /* COokie Notice */
    .bg-cookie.fixed-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        background: #000;
        padding: 2rem;

        .cookie-notice {
            display: flex;
            justify-content: space-between;
        }

        .btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 0.5rem 0.5rem;
            border-radius: 0;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 1;
            letter-spacing: 0.5px;
            height: 32px;
            margin-right: 0.5rem;
            border: 2px solid;

            &.btn-primary {
                border: 2px solid ${colors.main};
                background-color: ${colors.main};
                color: ${colors.white};
            }

            &.btn-secondary {
                background-color: transparent;
                color: ${colors.main};
            }
        }
    }

    // Algolia Search
    .ais-SearchBox {
        display: flex;
        align-items: center;
    }

    .ais-SearchBox-form {
        width: 100%;
        display: flex;

        input[type="search"] {
            width: 100%;
            background-color: ${colors.background};
            border: 1px solid ${colors.main};
            border-right: 0;
            padding: 0.5rem 1rem;

            &:focus {
                outline: none;
            }
        }

        button {
            background-color: ${colors.main};
            border: 1px solid ${colors.main};
            border-left: 0;
            padding: 0.5rem 1rem;
            color: ${colors.white};
            cursor: pointer;
        }
    }

    .ais-Search-box-wrapper {
        position: relative;
        margin-bottom: 3rem;
    }

    .ais-PoweredBy {
        width: 120px;
        position: absolute;
        bottom: 0;
        transform: translateY(120%);
        right: 0;
        color: #fff;
    }

    .ais-Hits-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    h3 .ais-Highlight > * {
        color: ${colors.main};
    }

    .ais-Highlight-highlighted,
    .ais-Snippet-highlighted {
        background-color: ${colors.secondary};
    }

    .ais-Pagination {
        margin-bottom: 2rem;

        .ais-Pagination-list {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            justify-content: center;
            align-content: center;

            .ais-Pagination-item {
                margin: 0 0.5rem;
                
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    width: 1.5rem;
                    height: 1.5rem;
                }
                
                &.ais-Pagination-item--selected a {
                    border-color: ${colors.main};
                    color: ${colors.main};
                }
            }
        }
    }

    :is(.widget-a11y-container, [id^='widget-a11y-container']) * {
        color: #000;
    }
`

export const EmbedStyle = createGlobalStyle`
  ${styledNormalize}

  html {
    box-sizing: border-box;
    font-family: 'GT Walsheim Pro', Arial, sans-serif;
  }

  *, *:before, *:after {
    margin-top: 0;
    box-sizing: inherit;
  }

  p {
    font-size: 15px;
  }
`
