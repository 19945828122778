import React from "react"
import { MapProvider } from "react-map-gl"
import I18n from "components/providers/I18n"
import Search from "components/providers/Search"
import User from "components/providers/User"
import { Script } from "gatsby"

export const wrapRootElement = ({ element }) => {
  return (
    <User>
      <MapProvider>
        <Search>{element}</Search>
      </MapProvider>
      <Script
        src="https://acceslibre.beta.gouv.fr/static/js/widget.js"
        type="text/javascript"
        async="true"
      ></Script>
    </User>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return <I18n defaultLang={props.pageContext.locale}>{element}</I18n>
}

export const onClientEntry = () => {
  const userLang = navigator.language.substring(0, 2)

  if (userLang !== "fr" && !window.location.pathname.startsWith("/en")) {
    window.location.pathname = `/en${window.location.pathname}`
  }
}
